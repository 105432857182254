import * as actionTypes from "../action/infoActionAstro";
// import { useHistory } from "react-router-dom";

const initialState = {
  astrologer: [],
  one_astrologer: "",
};

const reducer = (state = initialState, action) => {
  // const histroy = useHistory("/astrologer/1");
  switch (action.type) {
    case actionTypes.ASTRO_LIST:
      // //////console.log('The action reducer is working fine')
      const updatedList = action.val;
      // //////console.log(updatedList);
      return {
        ...state,
        astrologer: updatedList,
        // success: true,
      };
    case actionTypes.ONE_ASTRO:
      //////console.log("The single user has been fetched");
      return {
        ...state,
        one_astrologer: action.info,
        // success: true,
      };
    case actionTypes.DELETE_ASTRO:
      //////console.log("astrologer has been deleted");
      const updatedArray = state.astrologer.filter(
        (el) => el.id !== action.val
      );
      return {
        ...state,
        astrologer: updatedArray,
        // success: true,
      };
    case actionTypes.ADD_ASTRO:
      //////console.log("adding the new astrologer");
      // console.log("The success has been made true");
      const x = state.astrologer.concat({
        name: action.val.name,
        gender: action.val.gender,
        city: action.val.city,
        country: action.val.country,
        isCorrect: "0",
        birthTime: action.val.birthTime,
        DOB: action.val.DOB,
        id: action.val.id,
        role: "astrologer",
      });
      return {
        ...state,
        success: true,
        // astrologer: x,
      };
  }
  return state;
};

export default reducer;
