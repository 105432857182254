import React from "react";
const Login = React.lazy(() =>
  import("./views/pages/login/Login")
);
const Toaster = React.lazy(() =>
  import("./views/notifications/toaster/Toaster")
);
const Tables = React.lazy(() => import("./views/base/tables/Tables"));

const Breadcrumbs = React.lazy(() =>
  import("./views/base/breadcrumbs/Breadcrumbs")
);
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
const BasicForms = React.lazy(() => import("./views/base/forms/BasicForms"));

const Jumbotrons = React.lazy(() =>
  import("./views/base/jumbotrons/Jumbotrons")
);
const ListGroups = React.lazy(() =>
  import("./views/base/list-groups/ListGroups")
);
const Navbars = React.lazy(() => import("./views/base/navbars/Navbars"));
const Navs = React.lazy(() => import("./views/base/navs/Navs"));
const Paginations = React.lazy(() =>
  import("./views/base/paginations/Pagnations")
);
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
const ProgressBar = React.lazy(() =>
  import("./views/base/progress-bar/ProgressBar")
);
const Switches = React.lazy(() => import("./views/base/switches/Switches"));

const Tabs = React.lazy(() => import("./views/base/tabs/Tabs"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));
const BrandButtons = React.lazy(() =>
  import("./views/buttons/brand-buttons/BrandButtons")
);
const ButtonDropdowns = React.lazy(() =>
  import("./views/buttons/button-dropdowns/ButtonDropdowns")
);
const ButtonGroups = React.lazy(() =>
  import("./views/buttons/button-groups/ButtonGroups")
);
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
const Charts = React.lazy(() => import("./views/charts/Charts"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const CoreUIIcons = React.lazy(() =>
  import("./views/icons/coreui-icons/CoreUIIcons")
);
const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
const Brands = React.lazy(() => import("./views/icons/brands/Brands"));
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));
const Badges = React.lazy(() => import("./views/notifications/badges/Badges"));
const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() =>
  import("./views/theme/typography/Typography")
);
const Widgets = React.lazy(() => import("./views/widgets/Widgets"));
const Chat = React.lazy(() => import("./views/message/chat.js"));
const Users = React.lazy(() => import("./views/users/Users"));
const User = React.lazy(() => import("./views/users/User"));
const Astrologers = React.lazy(() => import("./views/astrologer/astrologers"));
const Astrologer = React.lazy(() => import("./views/astrologer/astrologer"));
const AddAstrologer = React.lazy(() =>
  import("./views/astrologer/newAstrologer")
);
const Disciples = React.lazy(() => import("./views/disciple/disciples"));
const Disciple = React.lazy(() => import("./views/disciple/disciple"));
const AddDisciple = React.lazy(() => import("./views/disciple/newDisciple"));
const Horoscopedesign = React.lazy(() =>
  import("./views/horoscope/Horoscopedesign")
);
const HoroscopeDetail = React.lazy(() =>
  import("./views/horoscope/horoscopeDetail")
);
const HoroscopeMessage = React.lazy(() =>
  import("./views/horoscope/horoscopeMessage")
);
const Notification = React.lazy(() =>
  import("./views/notification/notification")
);
const NotificationDetail = React.lazy(() =>
  import("./views/notification/notificationDetail")
);
const AddNotification = React.lazy(() =>
  import("./views/notification/newNotification")
);
const Offer_Detail = React.lazy(() => import("./views/offer/offerDetail"));
const Offers = React.lazy(() => import("./views/offer/offers"));
const Offer = React.lazy(() => import("./views/offer/offer"));
const Support = React.lazy(() => import("./views/support/support.js"));
const SupportDetail = React.lazy(() => import("./views/support/supportDetail"));
const Payment = React.lazy(() => import("./views/payment/payment"));
const PaymentDetail = React.lazy(() =>
  import("./views/payment/PaymentDetail/paymentDetail")
);
const Setting = React.lazy(() => import("./views/pages/settings/setting"));

const routes = [
  { path: "/login", exact: true, component: Login },
  { path: "/", exact: true, name: "Dashboard", component: Dashboard },
  { path: "/dashboard", exact: true, component: Dashboard },
  {
    path: "/message",
    exact: true,
    name: "Messages",
    component: Chat,
  },
  {
    path: "/message/:user_id",
    exact: true,
    name: "Message Detail",
    component: Chat,
  },
  { path: "/theme", name: "Theme", component: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", component: Colors },
  { path: "/theme/typography", name: "Typography", component: Typography },
  { path: "/base", name: "Base", component: Cards, exact: true },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
  { path: "/base/cards", name: "Cards", component: Cards },
  { path: "/base/carousels", name: "Carousel", component: Carousels },
  { path: "/base/collapses", name: "Collapse", component: Collapses },
  { path: "/base/forms", name: "Forms", component: BasicForms },
  { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  { path: "/base/navbars", name: "Navbars", component: Navbars },
  { path: "/base/navs", name: "Navs", component: Navs },
  { path: "/base/paginations", name: "Paginations", component: Paginations },
  { path: "/base/popovers", name: "Popovers", component: Popovers },
  { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
  { path: "/base/switches", name: "Switches", component: Switches },
  { path: "/base/tables", name: "Tables", component: Tables },
  { path: "/base/tabs", name: "Tabs", component: Tabs },
  { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  { path: "/buttons", name: "Buttons", component: Buttons, exact: true },
  { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/buttons/button-dropdowns",
    name: "Dropdowns",
    component: ButtonDropdowns,
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups,
  },
  {
    path: "/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons,
  },
  { path: "/charts", name: "Charts", component: Charts },
  { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", component: Flags },
  { path: "/icons/brands", name: "Brands", component: Brands },
  {
    path: "/notifications",
    name: "Notifications",
    component: Alerts,
    exact: true,
  },
  { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  { path: "/notifications/badges", name: "Badges", component: Badges },
  { path: "/notifications/modals", name: "Modals", component: Modals },
  { path: "/notifications/toaster", name: "Toaster", component: Toaster },
  { path: "/widgets", name: "Widgets", component: Widgets },
  { path: "/user/:page", exact: true, name: "Users", component: Users },
  {
    path: "/user-detail/:id/:page",
    exact: true,
    name: "User Details",
    component: User,
  },
  {
    path: "/astrologer/:page",
    exact: true,
    name: "Astrologers",
    component: Astrologers,
  },
  {
    path: "/astrologer-detail/:id/:page",
    exact: true,
    name: "Astrologer Details",
    component: Astrologer,
  },
  {
    path: "/new-astrologer",
    exact: true,
    name: "Astrologer",
    component: AddAstrologer,
  },
  {
    path: "/disciple/:page",
    exact: true,
    name: "Disciples",
    component: Disciples,
  },
  {
    path: "/disciple-detail/:id/:page",
    exact: true,
    name: "Disciple Details",
    component: Disciple,
  },
  {
    path: "/new-disciple",
    exact: true,
    name: "Disciple",
    component: AddDisciple,
  },
  {
    path: "/horoscope",
    exact: true,
    name: "Horoscope",
    component: Horoscopedesign,
  },
  {
    path: "/horoscope/:sign",
    exact: true,
    name: "Horoscope Detail",
    component: HoroscopeDetail,
  },
  {
    path: "/horoscope-message/:id/:sign",
    exact: true,
    name: "Horoscope Message",
    component: HoroscopeMessage,
  },
  {
    path: "/new-notification",
    exact: true,
    name: "Notification",
    component: AddNotification,
  },
  {
    path: "/notification/:page",
    exact: true,
    name: "Notifications",
    component: Notification,
  },
  {
    path: "/notification-detail/:id/:page",
    exact: true,
    name: "Notification Details",
    component: NotificationDetail,
  },
  {
    path: "/offer-detail/:id",
    exact: true,
    name: "Offer Details",
    component: Offer_Detail,
  },
  {
    path: "/offer/:page",
    exact: true,
    name: "Offers",
    component: Offers,
  },
  {
    path: "/new-offer",
    exact: true,
    name: "Generate Offer",
    component: Offer,
  },
  {
    path: "/support/:page",
    exact: true,
    name: "Support",
    component: Support,
  },
  {
    path: "/support-detail/:id",
    exact: true,
    name: "Support Detail",
    component: SupportDetail,
  },
  {
    path: "/payment/:page",
    exact: true,
    name: "Payments",
    component: Payment,
  },
  {
    path: "/payment-detail/:id",
    exact: true,
    name: "Payment Details",
    component: PaymentDetail,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    component: Setting,
  },
];

export default routes;
