import axios from "../../instances/instances";

export const HOROSCOPE_LIST = "HOROSCOPE_LIST";
export const HOROSCOPE_USER_LIST = "HOROSCOPE_USER_LIST";
export const HOROSCOPE_MESSSAGE_LIST = "HOROSCOPE_MESSAGE_LIST";
export const USER_OF_PARTICULAR_DATE = "USER_OF_PARTICULAR_DATE";
export const SCHEDULE_MESSAGE_LIST = "SCHEDULE_MESSAGE_LIST";
export const OPEN_ERROR_HOROSCOPE = "OPEN_ERROR_HOROSCOPE";

export const openErr = () => {
  return {
    type: OPEN_ERROR_HOROSCOPE,
  };
};

export const getHoroscopeList = (data) => {
  return {
    type: HOROSCOPE_LIST,
    data,
  };
};

export const gettingHoroscopeList = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/sign`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      // console.log(res.data.response);
      dispatch(getHoroscopeList(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getUserList = (data) => {
  return {
    type: HOROSCOPE_USER_LIST,
    data,
  };
};

export const gettingUserList = (sign) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/dashboard-userSign?sign=${sign}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      // console.log("userList>>>>>>",res.data.response)
      dispatch(getUserList(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getHoroscopeMessageList = (data) => {
  return {
    type: HOROSCOPE_MESSSAGE_LIST,
    val: data,
  };
};

export const gettingHoroscopeMessageList = (sign) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/dashboard/horoscope?name=${sign}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      // console.log(res);
      dispatch(getHoroscopeMessageList(res.data.response.nameList));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getUserForParticularDate = (data) => {
  // console.log(data)
  return {
    type: USER_OF_PARTICULAR_DATE,
    data,
  };
};

export const gettingUserForParticularDate = (date, sign) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `/dashboard/horoscope-user?date=${date}&sign=${sign}`,
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      // console.log(res);
      dispatch(getUserForParticularDate(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getScheduleMessagelist = (data) => {
  return {
    type: SCHEDULE_MESSAGE_LIST,
    data,
  };
};

export const gettingScheduledMessageList = (sign) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `/dashboard/horoscope?name=${sign}&type=schedule`,
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      // console.log(res.data.response.nameList);
      dispatch(getScheduleMessagelist(res.data.response.nameList));
    } catch (err) {
      dispatch(openErr());
    }
  };
};
