import axios from "../../instances/instances";

export const ADD_OFFER = "ADD_OFFER";
export const ALL_OFFER = "ALL_OFFER";
export const GET_OFFER = "GET_OFFER";
export const OPEN_ERROR_OFFER = "OPEN_ERROR_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";

export const openErr = () => {
  return {
    type: OPEN_ERROR_OFFER,
  };
};

export const addOffer = () => {
  return {
    type: ADD_OFFER,
  };
};

export const addingOffer = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/offer`, data, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      ////////console.log(res);
      dispatch(addOffer());
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getOfferList = (data) => {
  return {
    type: ALL_OFFER,
    val: data,
  };
};

export const gettingOfferList = (filter) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/dashboard/offer?isFree=${filter}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(getOfferList(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getSingleOffer = (data) => {
  return {
    type: GET_OFFER,
    val: data,
  };
};

export const gettingSingleOffer = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/dashboard/offer-detail/${id}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(getSingleOffer(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const updateOffer = () => {
  return {
    type: UPDATE_OFFER,
  };
};

export const updatingOffer = (data, id) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/update-offer/${id}`, data, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(updateOffer());
    } catch (err) {
      dispatch(openErr());
    }
    ////////console.log(res);
  };
};

export const deleteOffer = (id) => {
  return {
    type: DELETE_OFFER,
    id: id,
  };
};

export const deletingOffer = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/delete-offer/${id}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      ////console.log(res);
      dispatch(deleteOffer(id));
    } catch (err) {
      dispatch(openErr());
    }
  };
};
