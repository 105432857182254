import axios from "../../instances/instances";

export const USERS_LIST = "USERS_LIST";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const OPEN_ERROR_USER = "OPEN_ERROR_USER";

export const openErr = () => {
  // debugger;
  return {
    type: OPEN_ERROR_USER,
  };
};

export const getUserList = (data) => {
  return {
    type: USERS_LIST,
    data,
  };
};

export const gettingUserList = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/dashboard-user?role=user");
      dispatch(getUserList(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const sendNotification = () => {
  return {
    type: SEND_NOTIFICATION,
  };
};

export const sendingNotification = (data) => {
  return async (dispatch) => {
    try {
      // console.log(data)
      const res = await axios.post(`/notification`, data, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      // console.log(res);
      ////////console.log(res);
      dispatch(sendNotification());
    } catch (err) {
      dispatch(openErr());
    }
  };
};
