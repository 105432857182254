import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CBreadcrumbRouter,
} from "@coreui/react";
import Axios from "../instances/instances";
import { connect } from "react-redux";

// routes config
import routes from "../routes";

import {

  TheNotificationDropdown,
} from "./index";

import TheHeaderDropdown from "./TheHeaderDropdown";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TheHeader = (props) => {
  const [name, setName] = useState();
  const location = useLocation();

  const initialLoad = async () => {
    let a = location.pathname.split("/");
    const res = await Axios.get(`/getUser/${a[2]}`, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    setName(
      res && res.data && res.data.response && res.data.response.name
        ? res.data.response.name
        : null
    );
    let route = routes.findIndex((item) => item.name === "Message Detail");
    if (route != -1)
      routes[route].name =
        res &&
        res.data &&
        res.data.response &&
        res.data.response.name &&
        res.data.response.name;
  }

  useEffect(() => {
    initialLoad()
  }, [location.pathname])
  if (name && name != "") {
    let route = routes.findIndex((item) => item.path === "/message/:user_id");
    if (route != -1) routes[route].name = name;
  }

  const sidebarShow = props.sidebarShow;

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    props.sidebarToggle(val);
    // dispatch({ type: "set", sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    props.sidebarMobileToggle(val);
    // dispatch({ type: "set", sidebarShow: val });
  };
  // const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);
  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <h1>Esoterra</h1>
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3 c-d-md-down-none">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
       
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <TheNotificationDropdown />
        <TheHeaderDropdown />
      </CHeaderNav>

    </CHeader>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarShow: state.store.sidebarShow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sidebarToggle: (val) => dispatch({ type: "set", sidebarShow: val }),
    sidebarMobileToggle: (val) => dispatch({ type: "set", sidebarShow: val }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TheHeader);
