import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { SocketContext, socket } from "./context/socket";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { NotificationContainer } from "react-notifications";
import { TheLayout } from "./containers";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ForgetPassword = React.lazy(() =>
  import("./views/pages/login/forgetPassword")
);

class App extends Component {
  render() {
    return (
      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          <ToastContainer />
          <NotificationContainer />
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                component={Login}
              />

              <Route
                exact
                path="/forget-password"
                name="Forget Password"
                component={ForgetPassword}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                component={Register}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                component={Page404}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                component={Page500}
              />
             

              <Route
              path="/"
              name="Home"
              component={TheLayout}
            />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </SocketContext.Provider>
    );
  }
}

export default App;
