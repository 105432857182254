export const OPEN_ERROR = "OPEN_ERROR";
export const CLOSE_ERROR = "CLOSE_ERROR";

export const openErr = () => {
  return {
    type: OPEN_ERROR,
  };
};

export const closeErr = () => {
  return {
    type: CLOSE_ERROR,
  };
};
