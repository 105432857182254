import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL

const instance = axios.create({
  baseURL,
});

// instance.defaults.headers.common["x-access-token"] = localStorage.getItem(
//   "token"
// );

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      unAutherized();
    }
  }
);

const unAutherized = () => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    window.location.href = "/login";
  }
};

export default instance;
