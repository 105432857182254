import React from "react";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "react-notifications/lib/notifications.css";
import "bootstrap-social/bootstrap-social.css";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import ChatNotification from "./redux/store/chatNotiReducer";
import AstroList from "./redux/store/astroListReducer";
import DiscipleList from "./redux/store/discipleListReducer";
import UserList from "./redux/store/userListReducer";
import NotificationList from "./redux/store/notificationListReducer";
import OfferList from "./redux/store/offerListReducer";
import Error from "./redux/store/errorReducer";
import HoroscopeList from "./redux/store/horoscopeListReducer";
import "react-notifications-component/dist/theme.css";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Chat from "./redux/store/chatReducer";
import Store from "./store";

import { configureStore } from "@reduxjs/toolkit";

React.icons = icons;

const reducers = combineReducers({
  infoChatNotification: ChatNotification,
  infoAs: AstroList,
  infoDis: DiscipleList,
  infoUser: UserList,
  infoNotification: NotificationList,
  infoHoro: HoroscopeList,
  infoOffer: OfferList,
  chat: Chat,
  err: Error,
  store: Store,
});

// Create the Redux store using configureStore
const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <NotificationContainer />
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
