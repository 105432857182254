import * as actionTypes from "./../action/infoActionNotification";

const initialState = {
  notification: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_LIST:
      return {
        ...state,
        notification: action.val,
      };
  }
  return state;
};

export default reducer;
