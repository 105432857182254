import {
  ALL_CLEAR,
  CHAT_NOTIFICATION_LIST,
  ONE_NOTIFICATION,
} from "../action/infoActionChatNoti";

const initialState = {
  notification: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_NOTIFICATION_LIST:
      return {
        ...state,
        notification: action.data,
      };
    case ONE_NOTIFICATION:
      // console.log("action.id>>>>", action.id);
      // console.log("state.notification>>>>>", state.notification);
      // console.log("One_single notification  is here");
      let updatedArray = state.notification;
      updatedArray = state.notification.filter(
        (item) => item.user_id !== action.id
      );
      return {
        ...state,
        notification: updatedArray,
      };
    case ALL_CLEAR:
      return {
        ...state,
        notification: [],
      };
  }
  return state;
};

export default reducer;
