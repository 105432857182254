import React, { useEffect, useState } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// import CIcon from "@coreui/icons-react";
import { toast } from "react-toastify";
import "./Notification.css";
import axios from "../instances/instances";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import {
  allNotificationClear,
  gettingChatNotification,
  oneNotificationClear,
} from "src/redux/action/infoActionChatNoti";

const TheNotificationDropdown = (props) => {
  const history = useHistory();

  const [data, setData] = useState("");

  useEffect(() => {
    props.getNotification();
  }, []);

  useEffect(() => {
    setData(props.notificationList);
  }, [props.notificationList]);

  const showMessageHandler = async (id) => {
    // //console.log(id);
    try {
      // //console.log("it is getting list");
      await props.getSingleNotification(id);
      history.push(`/message/${id}`);
    } catch (err) {
      // toast.error(<span className="fa fa-lg"> Internal Server Error !</span>, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 2000,
      // });
    }
  };

  const markAllRead = async () => {
    try {
      await props.getAllClear();
    } catch (err) {
      toast.error(<span className="fa fa-lg"> Internal Server Error !</span>, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  // //console.log(props.notificationList);

  const getDate = (date) => {
    const x = date.split(" ");
    //console.log(x[1]);
    return x[1];
  };

  const itemsCount = data ? data.length : 0;

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2 ddfdfd"
      direction="down"
    >
      {/* <ToastContainer /> */}
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-envelope-open" />
        <CBadge shape="pill" color="info">
          {itemsCount !== 0 ? itemsCount : ""}
        </CBadge>
      </CDropdownToggle>
      {/* <CDropdownMenu className="pt-0 dfdf" placement="bottom-end"> */}
      <CDropdownMenu>
        {/* <CDropdownItem header tag="div" className="text-center" color="dark">
          <strong style={{ color: "white" }}>Notifications</strong>
        </CDropdownItem> */}
        <section id="notification_design">
          <div class="notification-first">
            <h2>Notifications </h2>
            {data && data.length > 0 ? (
              <div className="Markread">
                <h4 onClick={markAllRead}>Mark all as read</h4>
                {/* <span onClick={markAllRead}>Mark All Read</span> */}
              </div>
            ) : null}
          </div>
          <hr />
          <div className="col-lg-12 notificationPanel" style={{}}>
            {data && data.length > 0 ? (
              data.map((item) => {
                // //console.log(item);
                return (
                  <div
                    className="row rowEffect"
                    key={item.id ? item.id : ""}
                    onClick={(e) =>
                      showMessageHandler(item ? item.user_id : "")
                    }
                  >
                    <div className="col-lg-9">
                      <div className="mumber_name">
                        <div className="profile">
                          <img
                            src={
                              item && item.profile_pic
                                ? item.profile_pic
                                : "avatars/12.png"
                            }
                            alt="Notification"
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                              border: "1px solid grey",
                            }}
                          />
                        </div>
                        <div className="profile_name">
                          <h3>{item.name ? item.name : ""}</h3>
                          <h6>
                            {item.notification
                              ? item.notification.length > 50
                                ? item.notification.slice(0, 50) + "..."
                                : item.notification
                              : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3" style={{ color: "#8e8c8c" }}>
                      {item && item.createdAt ? item.createdAt : ""}
                      {/* {getDate(item && item.createdAt ? item.createdAt : "")} */}
                    </div>
                  </div>
                  // <div
                  //   key={item.id ? item.id : ""}
                  //   className="row rowStyle"
                  //   style={{
                  //     padding: "15px 0px 3px 10px",
                  //   }}
                  //   onClick={(e) => showMessageHandler(item ? item.user_id : "")}
                  // >
                  //   <div className="col-1">
                  //     <CImg
                  //       src={
                  //         item && item.profile_pic
                  //           ? item.profile_pic
                  //           : "avatars/12.png"
                  //       }
                  //       alt="Notification"
                  //       style={{
                  //         height: "40px",
                  //         width: "40px",
                  //         borderRadius: "50%",
                  //         border: "1px solid grey",
                  //       }}
                  //     />
                  //   </div>
                  //   <div className="ml-3 col-9">
                  //     <span>
                  //       {item.name ? item.name : ""} ,{" "}
                  //       {item.sign ? item.sign : ""}
                  //     </span>
                  //     <span className="float-right">
                  //       {item.createdAt ? item.createdAt : ""}
                  //     </span>
                  //     <p>
                  //       <strong> Notification : </strong>{" "}
                  //       {item.notification
                  //         ? item.notification.length > 50
                  //           ? item.notification.slice(0, 50) + "..."
                  //           : item.notification
                  //         : ""}
                  //     </p>
                  //   </div>
                  // </div>
                );
              })
            ) : (
              <h3 style={{ textAlign: "center" }}>No new messages</h3>
            )}
          </div>
        </section>
      </CDropdownMenu>
    </CDropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationList: state.infoChatNotification.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotification: () => dispatch(gettingChatNotification()),
    getSingleNotification: (id) => dispatch(oneNotificationClear(id)),
    getAllClear: () => dispatch(allNotificationClear()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TheNotificationDropdown);
