import * as actionTypes from "../action/chatAction";

const initialState = {
  id: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHAT_ID: {
      return {
        ...state,
        id: action.data,
      };
    }
  }
  return state;
};

export default reducer;
