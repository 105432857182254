import React, { useEffect, useState } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { toast } from "react-toastify";
import axios from "axios";
import { FiSettings } from "react-icons/fi";

import { useHistory } from "react-router-dom";

const TheHeaderDropdown = () => {
  let history = useHistory();

  const [photo, setPhoto] = useState("");

  const [id, setId] = useState(localStorage.getItem("id"));

  const headers = {
    "x-access-token": localStorage.getItem("token"),
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getUser/${id}`, { headers })
      .then((res) =>{
        res.data.response.profile_pic
          ? setPhoto(res.data.response.profile_pic)
          : ''}
      );
  }, []);

  const logoutControl = (e) => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    toast.success(
      <span className="fa fa-check fa-lg"> Logged Out Successfully</span>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
    );
    setTimeout(() => {
      history.replace("/login")
    }, 3000);
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          {photo ? (
            <CImg
              src={photo}
              alt="admin@bootstrapmaster.com"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                border: "1px solid grey",
              }}
            />
          ) : (
            <CImg
              src={"/avatars/12.png"}
           
              alt="admin@bootstrapmaster.com"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                border: "1px solid grey",
              }}
            />
          )}
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          onClick={(e) => history.push("/settings")}
          style={{
            backgroundColor: "white",
            boxShadow: "5px 3px 9px 1px #6f6d6d",
          }}
        >
          <FiSettings className="mr-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem
          onClick={(e) => logoutControl(e)}
          style={{
            backgroundColor: "white",
            boxShadow: "5px 3px 9px 1px #6f6d6d",
          }}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
