import React from "react";
import CIcon from "@coreui/icons-react";
import "font-awesome/css/font-awesome.css";
import { FaForum, FaSquare, FaUserTie } from "react-icons/fa";
// import {chat-FaSquare} from 'react-icons';
import { BsPeople } from "react-icons/bs";
import { FiAward } from "react-icons/fi";
// import { BiDollar } from "react-icons/bi";

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/",
    icon: "cil-home",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Messages",
    to: "/message",
    icon: "cil-envelope-closed",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/user/1",
    icon: (
      <span className="mr-3 ml-1 fa-lg">
        <BsPeople />
      </span>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Astrologers",
  //   to: "/astrologer/1",
  //   icon: "cil-user",
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Disciples",
  //   to: "/disciple/1",
  //   icon: "cil-user",
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Horoscope",
    to: "/horoscope",
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Notifications",
    to: "/notification/1",
    icon: "cil-speech",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Offers",
    to: "/offer/1",
    icon: (
      <span className="mr-3 ml-1 fa-lg">
        <FiAward />
      </span>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Payments",
    to: "/payment/1",
    icon: (
      <span className="mr-3 ml-1 fa-lg paymentset">
        <img src="/avatars/22.png" />
      </span>
    ),
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Support",
  //   to: "/support/1",
  //   icon: (
  //     <span className="mr-3 ml-1 fa-lg">
  //       <FaUserTie />
  //     </span>
  //   ),
  // },
];

export default _nav;
