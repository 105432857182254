import axios from "../../instances/instances";

export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const OPEN_ERROR_NOTIFICATION = "OPEN_ERROR_NOTIFICATION";

export const openErr = () => {
  return {
    type: OPEN_ERROR_NOTIFICATION,
  };
};

export const getAllList = (data) => {
  return {
    type: NOTIFICATION_LIST,
    val: data,
  };
};

export const gettingAllNotificationList = () => {
  return async (dispatch) => {
    try {
      // console.log(headers)
      const res = await axios.get(`/dashboard/notification`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      ////////////////console.log(res.data.response);
      dispatch(getAllList(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};
