import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { connect } from "react-redux";

// sidebar nav config
import navigation from "./_nav";

const TheSidebar = (props) => {
  // const dispatch = useDispatch();
  const show = props.sidebarShow;

  return (
    <CSidebar show={show} onShowChange={(val) => props.sidebarToggle(val)}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <h1 className="c-sidebar-brand-full" name="logo-negative" height={35}>
          {" "}
          Esoterra
        </h1>
        <p
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
          style={{ fontSize: "10px" }}
        ></p>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarShow: state.store.sidebarShow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sidebarToggle: (val) => dispatch({ type: "set", sidebarShow: val }),
    sidebarMobileToggle: (val) => dispatch({ type: "set", sidebarShow: val }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TheSidebar));
