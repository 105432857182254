import * as actionTypes from "./../action/infoActionError";
import * as actionTypesAstro from "./../action/infoActionAstro";
import * as actionTypesDesci from "./../action/infoActionDesci";
import * as actionTypesNotification from "./../action/infoActionNotification";
import * as actionTypesOffer from "./../action/infoActionOffer";
import * as actionTypesUser from "./../action/infoActionUser";
import * as actionTypeHoroscope from "./../action/infoActionHoroscope";
import * as actionTypesChatNotification from "./../action/infoActionChatNoti";

const initialState = {
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_ERROR:
      return {
        ...state,
        error: true,
      };
    case actionTypes.CLOSE_ERROR:
      return {
        ...state,
        error: false,
      };
    case actionTypesAstro.OPEN_ERROR_ASTRO:
      return {
        ...state,
        error: true,
      };
    case actionTypesDesci.OPEN_ERROR_DESCI:
      return {
        ...state,
        error: true,
      };
    case actionTypesNotification.OPEN_ERROR_NOTIFICATION:
      return {
        ...state,
        error: true,
      };
    case actionTypesOffer.OPEN_ERROR_OFFER:
      return {
        ...state,
        error: true,
      };
    case actionTypesUser.OPEN_ERROR_USER:
      return {
        ...state,
        error: true,
      };
    case actionTypeHoroscope.OPEN_ERROR_HOROSCOPE:
      return {
        ...state,
        error: true,
      };
    case actionTypesChatNotification.OPEN_ERROR_CHAT:
      return {
        ...state,
        error: true,
      };
  }
  return state;
};

export default reducer;
