import axios from "../../instances/instances";

export const ASTRO_LIST = "ASTRO_LIST";
export const ONE_ASTRO = "ONE_ASTRO";
export const DELETE_ASTRO = "DELETE_ASTRO";
export const ADD_ASTRO = "ADD_ASTRO";
export const OPEN_ERROR_ASTRO = "OPEN_ERROR_ASTRO";

export const openErr = () => {
  // debugger;
  return {
    type: OPEN_ERROR_ASTRO,
  };
};

export const getAstroList = (list) => {
  return {
    type: ASTRO_LIST,
    val: list,
  };
};

export const gettingAstroList = () => {
  // console.log(localStorage.getItem("token"));
  return async (dispatch) => {
    try {
      const list = await axios.get(`/dashboard-user?role=astrologer`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(getAstroList(list.data.response));
      // ////////console.log(list.data.response);
    } catch (err) {
      // alert("coming from fetching astrologer");
      dispatch(openErr());
    }
  };
};

export const getAstro = (data) => {
  return {
    type: ONE_ASTRO,
    info: data,
  };
};

export const gettingAstro = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/getUser/${id}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(getAstro(res.data.response));
    } catch (err) {
      // alert("coming from fetching particular astrologer");
      dispatch(openErr());
      // console.log(err)
    }
  };
};

export const deleteAstro = (id) => {
  return {
    type: DELETE_ASTRO,
    val: id,
  };
};

export const deletingAstro = (id) => {
  // console.log("deleting astrologer");
  return async (dispatch) => {
    try {
      // console.log(headers);
      const res = await axios.post(
        `/astrologer/${id}`,
        {},
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      //////console.log(res);
      dispatch(deleteAstro(id));
    } catch (err) {
      // alert("coming from deleting astrologer");
      dispatch(openErr());
    }
  };
};

export const addAstro = (data) => {
  return {
    type: ADD_ASTRO,
    val: data,
  };
};

export const addingAstro = (data) => {
  // console.log("adding astrologer");
  return async (dispatch) => {
    try {
      const res = await axios.post(`/user`, data, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      // dispatch(successTrue());
      dispatch(addAstro(res.data.response));
    } catch (err) {
      // alert("coming from adding astrologer");
      dispatch(openErr());
    }
  };
};
