import * as actionTypes from "../action/infoActionHoroscope";

const initialState = {
  horoscope: [],
  userList: [],
  message: [],
  scheduleMessage: [],
  particularDateUser: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HOROSCOPE_LIST:
      return {
        ...state,
        horoscope: action.data,
      };
    case actionTypes.HOROSCOPE_USER_LIST:
      // console.log('state has been set for users')
      // console.log(action.data)
      return {
        ...state,
        userList: action.data,
      };
    case actionTypes.HOROSCOPE_MESSSAGE_LIST:
      // debugger
      return {
        ...state,
        message: action.val,
      };
    case actionTypes.SCHEDULE_MESSAGE_LIST:
      return {
        ...state,
        scheduleMessage: action.data,
      };
    case actionTypes.USER_OF_PARTICULAR_DATE:
      // console.log("from reducer>>>>>>",action.data)
      return {
        ...state,
        particularDateUser: action.data,
      };
  }
  return state;
};

export default reducer;
