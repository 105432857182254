import * as actionTypes from "./../action/infoActionDesci";

const initialState = {
  disciple: [],
  one_disciple: "",
  toaster: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DESCI_LIST:
      // //////console.log('The desciple reducer is working fine');
      const updatedList = action.val;
      // //////console.log(updatedList);
      return {
        ...state,
        disciple: updatedList,
      };
    case actionTypes.ADD_DESCI:
      //////console.log(action.val);
      const x = state.disciple.concat({
        name: action.val.name,
        gender: action.val.gender,
        city: action.val.city,
        country: action.val.country,
        isCorrect: "0",
        birthTime: action.val.birthTime,
        DOB: action.val.DOB,
        id: action.val.id,
        role: "disciple",
      });
      return {
        ...state,
        // disciple: x,
      };
    case actionTypes.ONE_DESCI:
      //////console.log("The single user has been fetched " + action.info);
      return {
        ...state,
        one_disciple: action.info,
      };
    case actionTypes.DELETE_DESCI:
      //////console.log("disciple has been deleted");
      const updatedArray = state.disciple.filter((el) => el.id !== action.val);
      return {
        ...state,
        disciple: updatedArray,
        toaster: true,
      };
    case actionTypes.TOAST_HANDLER:
      return {
        ...state,
        toaster: false,
      };
  }
  return state;
};

export default reducer;
