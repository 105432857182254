// import { useHistory } from "react-router-dom";
import axios from "../../instances/instances";

export const DESCI_LIST = "DESCI_LIST";
export const ADD_DESCI = "ADD_DESCI";
export const ONE_DESCI = "ONE_DESCI";
export const DELETE_DESCI = "DELETE_DESCI";
export const TOAST_HANDLER = "TOAST_HANDLER";
export const OPEN_ERROR_DESCI = "OPEN_ERROR_DESCI";

export const openErr = () => {
  return {
    type: OPEN_ERROR_DESCI,
  };
};

export const toastHandler = () => {
  return {
    type: TOAST_HANDLER,
  };
};

export const getDesciList = (list) => {
  return {
    type: DESCI_LIST,
    val: list,
  };
};

export const gettingDesciList = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/dashboard-user?role=disciple`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(getDesciList(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const addDesci = (data) => {
  return {
    type: ADD_DESCI,
    val: data,
  };
};

export const addingDesci = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/user`, data, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(addDesci(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const getDisci = (data) => {
  return {
    type: ONE_DESCI,
    info: data,
  };
};

export const gettingDesciple = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/getUser/${id}`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      dispatch(getDisci(res.data.response));
    } catch (err) {
      dispatch(openErr());
    }
  };
};

export const deleteDisci = (id) => {
  return {
    type: DELETE_DESCI,
    val: id,
  };
};

export const deletingDisci = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `/astrologer/${id}`,
        {},
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      dispatch(deleteDisci(id));
    } catch (err) {
      dispatch(openErr());
    }
  };
};
