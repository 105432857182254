import React from "react";
import { TheContent, TheSidebar, TheFooter } from "./index";
import TheHeader from "./TheHeader";

const TheLayout = () => {
  return (
    <div className="c-app c-default-layout">
    <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        </div>
        {/* <TheFooter /> */}
      </div>
  );
};

export default TheLayout;
