import * as actionTypes from "./../action/infoActionOffer";

const initialState = {
  offer: [],
  one_offer: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_OFFER:
      return { ...state };
    case actionTypes.ALL_OFFER:
      return {
        ...state,
        offer: action.val,
      };
    case actionTypes.GET_OFFER:
      return {
        ...state,
        one_offer: action.val,
      };
    case actionTypes.UPDATE_OFFER:
      return {
        ...state,
      };
    case actionTypes.DELETE_OFFER:
      const updateArray = state.offer.filter((item) => item.id !== action.id);
      return {
        ...state,
        offer: updateArray,
      };
  }
  return state;
};

export default reducer;
