import axios from "../../instances/instances";

export const CHAT_NOTIFICATION_LIST = "CHAT_NOTIFICATION_LIST";
export const ONE_NOTIFICATION = "ONE_NOTIFICATION";
export const ALL_CLEAR = "ALL_CLEAR";
export const OPEN_ERROR_CHAT = "OPEN_ERROR_CHAT";

export const openErr = () => {
  return {
    type: OPEN_ERROR_CHAT,
  };
};

export const getNotificationList = (data) => {
  return {
    type: CHAT_NOTIFICATION_LIST,
    data,
  };
};

export const gettingChatNotification = () => {
  // //console.log("This is getting a hit");
  //console.log(localStorage.getItem("token"));
  return async (dispatch) => {
    try {
      setTimeout(async () => {
        const res = await axios.get("/dashboard/notification-list", {
          headers: { "x-access-token": localStorage.getItem("token") },
        });
        // //console.log(res);
        if (res) {
          dispatch(getNotificationList(res.data.response));
        }
      }, 1000);
    } catch (err) {
      // dispatch(openErr());
    }
  };
};

export const oneNotification = (id) => {
  return {
    type: ONE_NOTIFICATION,
    id,
  };
};

export const oneNotificationClear = (id) => {
  return async (dispatch) => {
    // //console.log("iit is coming here");
    try {
      const res = await axios.post(
        `/dashboard/notification/${id}`,
        {},
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      dispatch(oneNotification(id));
    } catch (err) {
      // dispatch(openErr());
    }
  };
};

export const allClear = () => {
  return {
    type: ALL_CLEAR,
  };
};

export const allNotificationClear = () => {
  return async (dispatch) => {
    try {
      //console.log("efewf");
      const res = await axios.post(
        `/dashboard/update-notification`,
        {},
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      // //console.log(res);
      dispatch(allClear());
    } catch (err) {
      // dispatch(openErr());
    }
  };
};
